
/**
 * 针对联想域名限制所做的图片加载处理
*/

// cdn链接地址
const processCDN = (process.env.CDN || '').replace(/\/$/, ''); // 这里判断环境变量中是否含有CDN地址并去除租后一位的 /
const imgCdnUrl = processCDN ? processCDN : 'https://educenter-lenovo-cdn.forclass.net';
console.log('CDN URL:', imgCdnUrl);
// 当前所在项目的前缀
const projectType = 'Account';

// 判断当前图片链接是否存在
function checkImageExists(imageUrl, updateIdx = 1, callback) {
    var img = new Image();
    img.onload = function () {
        callback(true, updateIdx);
    };
    img.onerror = function () {
        callback(false, updateIdx);
    };

    img.src = imageUrl;
}

// 设置图片地址
// imageUrl - 原本的图片地址；type - 当前所在项目
function setImageCdnUrl(originSrc) {
    // 比如：@/assets/img/site/logo_lh_qq.png
    if (/^@\//.test(originSrc)) {
        originSrc = originSrc.replace(/^@\//, '/').replace(/^\/assets\//, '/');
    }

    if (!/^\//.test(originSrc)) {
        originSrc = `/${originSrc}`;
    }

    // 这里需要注意当开头为 /static，即代表为 /Cm 【不知道是否为教学教研的特例】
    originSrc = originSrc.replace(/^\/static/, `/${projectType}`);

    // 开头是否带有项目名，不带有帮忙添加
    let start = new RegExp(`^\/${projectType}`);
    if (!start.test(originSrc)) {
        originSrc = `/${projectType}${originSrc}`
    }

    return `${imgCdnUrl}${originSrc}`
}

const isOnlineImgReg = /^(http|https|HTTP|HTTPS)?:\/\/.*\.(png|jpg|jpeg|gif|bmp)$/i;
const isBase64ImgReg = /^data:image\/(png|jpeg|jpg|gif|bmp);base64,/;

export default {
    install(Vue) {
        // vue2 自定义指令 directive 文档地址：
        // https://v2.cn.vuejs.org/v2/guide/custom-directive.html
        Vue.directive('cdnimg', function (el, binding, vnode, oldVnode) {
            let originSrc = vnode && vnode.data && vnode.data.attrs ? vnode.data.attrs.src : el.getAttribute('src'); // 新的改变后的值
            let oldSrc = el.oldSrc || ''; // 旧得值

            // console.log('当前的图片节点：', el, vnode, originSrc, oldSrc);
            // 若为图片节点并且图片地址存在
            if (el.nodeName === 'IMG' && typeof originSrc === 'string') {
                // 是否为线上图片
                let isOnlineImg = isOnlineImgReg.test(originSrc);
                // 是否为base64图片
                let isBase64Img = isBase64ImgReg.test(originSrc);
                if (!isBase64Img) {
                    let imageUrl = isOnlineImg ? originSrc : setImageCdnUrl(originSrc);
                    // 若将要改变的值和前一个改变的旧值一样，则图片地址不进行更改（防止重复刷新图片）
                    if(imageUrl === oldSrc){
                        return
                    }
                    // 图片地址先置空【避免在判断是否存在的过程中渲染原地址链接的图片，从而导致的重复渲染】
                    el.setAttribute('src', '');
                    /**
                     * 这里是针对src不确定会多次变化时所做的调整
                     * 1、因为节点是不会变化的，所以我们为节点添加一个变化次数的属性 updateIdx
                     * 2、每次变化时都需要将 updateIdx 值加一
                     * 3、因为判断当前图片链接是否正确会有延时，所以应该以变化的最后一次为准 （即 updateIdx >= el.updateIdx）
                     * */
                    if (typeof el.updateIdx === 'undefined') {
                        el.updateIdx = 1;
                    } else {
                        el.updateIdx += 1;
                    }
                    // 判断图片是否存在
                    checkImageExists(imageUrl, el.updateIdx, function (isExited, updateIdx) {
                        if (updateIdx >= el.updateIdx) {
                            // console.log('判断图片是否存在的结果：', el, isExited, updateIdx, el.updateIdx);
                            // 若cdn图片存在则直接进行替换，不存在则还是使用原本的
                            if (isExited) {
                                el.setAttribute('src', imageUrl);
                                
                            } else {
                                el.setAttribute('src', originSrc);
                            }

                            el.oldSrc = isExited ? imageUrl : originSrc; // 将当前改变后的值保存为一个旧值
                        }
                    });
                } else {
                    // console.log('没有被选中的', el, isBase64Img, isOnlineImg, originSrc);
                }
            }
        });
    }
}