<template>
  <div id="app">
    <router-view/>
    <loading />
    <quick-tool-bar/>
  </div>
</template>
<script>
import quickToolBar from '@/components/quickToolBar.vue'
import Loading from "@/components/Loading.vue"
export default {
  beforeCreate(){
    // 立芝教师账号
    // document.cookie = 'account=%7B%22userid%22%3A9616%2C%22session%22%3A%22F65ED8A3C4F24947BF447693084CB15%22%2C%22loginname%22%3A%22PPD002%22%2C%22realname%22%3A%22%E5%88%98%E7%AB%8B%E8%8A%9D%22%2C%22account%22%3A%22PPD002%22%2C%22role%22%3A%22%E6%95%99%E5%B8%88%22%2C%22period%22%3A%22%E5%88%9D%E4%B8%AD%22%2C%22coin%22%3A6345%2C%22level%22%3Anull%2C%22email%22%3Anull%2C%22avatar%22%3A%22https%3A%2F%2Ffordata.forclass.net%2FLiveBroadcast%2FActivity%2F9616%2F2022_01_21_1642731762039.jpg%22%2C%22dlserver%22%3A%22http%3A%2F%2Ffcdata.forclass.net%2F%22%2C%22zoneidx%22%3A5%2C%22schoolidx%22%3A11%2C%22role1%22%3A%22%E6%95%99%E5%B8%88%22%2C%22classidx%22%3A0%2C%22classname%22%3A%22%22%2C%22zonename%22%3A%22%E5%88%9B%E8%80%8C%E6%96%B0%E6%B5%8B%E8%AF%95%E4%B8%93%E5%8C%BA%22%2C%22schoolname%22%3A%22%E5%88%9B%E8%80%8C%E6%96%B0PPD%E6%B5%8B%E8%AF%95%E5%AD%A6%E6%A0%A1%22%2C%22zonelevel%22%3A2%2C%22platformzonelevel%22%3A1%2C%22zonelevelname%22%3A%22%E7%9C%81%22%2C%22showname%22%3A%22PPD002%22%2C%22sourceid%22%3A0%7D'   
    // 普通教师账号
    // document.cookie = 'account=%7B%22userid%22%3A9600%2C%22session%22%3A%22EBC4BE9140F69C59E69A5914671%22%2C%22showname%22%3A%22SQA006%22%2C%22realname%22%3A%22%E7%B2%B1%E8%8B%97%E8%8B%97sqa006%22%2C%22role%22%3A%22%E6%95%99%E5%B8%88%22%2C%22period%22%3A%22%E5%B0%8F%E5%AD%A6%22%2C%22coin%22%3A3084%2C%22level%22%3Anull%2C%22email%22%3Anull%2C%22avatar%22%3A%22http%3A%2F%2Ffcdata.forclass.net%2FAttachFiles%2F9600%2Favatar_SQA006_1636508638697.png%3Fx-oss-process%3Dimage%2Fcrop%2Cx_273%2Cy_0%2Cw_282%2Ch_282%2Cimage%2Fresize%2Cm_fill%2Ch_140%2Cw_140%22%2C%22dlserver%22%3A%22http%3A%2F%2Ffcdata.forclass.net%2F%22%2C%22zoneidx%22%3A5%2C%22schoolidx%22%3A10%2C%22school%22%3A%22%E5%88%9B%E8%80%8C%E6%96%B0SQA%E6%B5%8B%E8%AF%95%E5%AD%A6%E6%A0%A1%22%2C%22grade%22%3Anull%2C%22term%22%3Anull%2C%22account%22%3A%22SQA006%22%2C%22loginname%22%3A%22SQA006%22%2C%22role1%22%3A%22%E6%95%99%E5%B8%88%22%2C%22classidx%22%3A0%2C%22classname%22%3A%22%22%2C%22zonename%22%3A%22%E5%88%9B%E8%80%8C%E6%96%B0%E6%B5%8B%E8%AF%95%E4%B8%93%E5%8C%BA%22%2C%22schoolname%22%3A%22%E5%88%9B%E8%80%8C%E6%96%B0SQA%E6%B5%8B%E8%AF%95%E5%AD%A6%E6%A0%A1%22%2C%22sourceid%22%3A0%7D'
    // 学生账号
    // document.cookie = 'account=%7B%22userid%22%3A7979431%2C%22session%22%3A%22AFEB0B885B014FCEAC9CB7EB67DB13C%22%2C%22loginname%22%3A%22anazjcs02s01%22%2C%22realname%22%3A%2201%E5%AD%A6%E7%94%9F01%22%2C%22account%22%3A%22anazjcs02s01%22%2C%22role%22%3A%22%E5%AD%A6%E7%94%9F%22%2C%22period%22%3A%22%E5%B0%8F%E5%AD%A6%22%2C%22coin%22%3A0%2C%22level%22%3Anull%2C%22email%22%3Anull%2C%22avatar%22%3Anull%2C%22dlserver%22%3A%22http%3A%2F%2Ffcdata.forclass.net%2F%22%2C%22zoneidx%22%3A121343%2C%22schoolidx%22%3A340872%2C%22role1%22%3A%22%E5%AD%A6%E7%94%9F%22%2C%22classidx%22%3A69551%2C%22classname%22%3A%22%E6%B5%8B%E8%AF%952%E7%8F%AD%22%2C%22zonename%22%3A%22%E6%99%BA%E6%9D%B0%E6%95%99%E8%82%B2%22%2C%22schoolname%22%3A%22%E6%99%BA%E6%9D%B0%E6%B5%8B%E8%AF%95%E5%AD%A6%E6%A0%A1%EF%BC%88%E5%88%9B%E8%80%8C%E6%96%B0%EF%BC%89%22%2C%22zonelevel%22%3A1%2C%22platformzonelevel%22%3A1%2C%22zonelevelname%22%3A%22%E5%85%B6%E4%BB%96%E4%B8%93%E5%8C%BA%22%2C%22showname%22%3A%22anazjcs02s01%22%2C%22sourceid%22%3A0%7D'
    // 区域管理员账号
    // document.cookie = 'account={%22userid%22:7979296%2C%22session%22:%22F2EFC9F977714AB0A8D802F2FFBCE95%22%2C%22loginname%22:%22anazjjy-adm01%22%2C%22realname%22:%22%E5%8C%BA%E5%9F%9F%E7%AE%A1%E7%90%86%E5%91%98%22%2C%22account%22:%22%E5%8C%BA%E5%9F%9F%E7%AE%A1%E7%90%86%E5%91%98%22%2C%22role%22:%22%E5%8C%BA%E5%9F%9F%E7%AE%A1%E7%90%86%E5%91%98%22%2C%22period%22:null%2C%22coin%22:0%2C%22level%22:null%2C%22email%22:null%2C%22avatar%22:null%2C%22dlserver%22:%22http://fcdata.forclass.net/%22%2C%22zoneidx%22:121343%2C%22schoolidx%22:0%2C%22role1%22:%22%E5%8C%BA%E5%9F%9F%E7%AE%A1%E7%90%86%E5%91%98%22%2C%22classidx%22:0%2C%22classname%22:%22%22%2C%22zonename%22:%22%E6%99%BA%E6%9D%B0%E6%95%99%E8%82%B2%22%2C%22schoolname%22:null%2C%22zonelevel%22:1%2C%22platformzonelevel%22:1%2C%22zonelevelname%22:%22%E5%85%B6%E4%BB%96%E4%B8%93%E5%8C%BA%22%2C%22showname%22:%22anazjjy-adm01%22%2C%22sourceid%22:0}'
    // 学校管理员账号
    // document.cookie = 'account={%22userid%22:7979451%2C%22session%22:%22B3E59844685B1DD180A5EF35B7%22%2C%22loginname%22:%22anazjcs-adm01%22%2C%22realname%22:%22%E5%AD%A6%E6%A0%A1%E7%AE%A1%E7%90%86%E5%91%98%22%2C%22account%22:%22%E5%AD%A6%E6%A0%A1%E7%AE%A1%E7%90%86%E5%91%98%22%2C%22role%22:%22%E5%AD%A6%E6%A0%A1%E7%AE%A1%E7%90%86%E5%91%98%22%2C%22period%22:null%2C%22coin%22:0%2C%22level%22:null%2C%22email%22:null%2C%22avatar%22:null%2C%22dlserver%22:%22http://fcdata.forclass.net/%22%2C%22zoneidx%22:121343%2C%22schoolidx%22:340872%2C%22role1%22:%22%E5%AD%A6%E6%A0%A1%E7%AE%A1%E7%90%86%E5%91%98%22%2C%22classidx%22:0%2C%22classname%22:%22%22%2C%22zonename%22:%22%E6%99%BA%E6%9D%B0%E6%95%99%E8%82%B2%22%2C%22schoolname%22:%22%E6%99%BA%E6%9D%B0%E6%B5%8B%E8%AF%95%E5%AD%A6%E6%A0%A1%EF%BC%88%E5%88%9B%E8%80%8C%E6%96%B0%EF%BC%89%22%2C%22zonelevel%22:1%2C%22platformzonelevel%22:1%2C%22zonelevelname%22:%22%E5%85%B6%E4%BB%96%E4%B8%93%E5%8C%BA%22%2C%22showname%22:%22anazjcs-adm01%22%2C%22sourceid%22:0}'
    
    this.validateSession((response)=>{
      let userInfo = response.data.result[0]
      // this.$bus.$emit('userInfo',userInfo)  // 向修改资料组件里面传递数据
    })
  },
  components: {
    quickToolBar,
    Loading
  },
  created(){
    // 获取顶部导航信息
    let that = this
    // this.getNavList(function(platform) {
    //     that.Platform = platform
    //     let account = that.loadAccount()
    //     let path = location.pathname
    //     if(path == '/' && account && (account.role == '学生' || account.role == '家长')) {
    //         let alias = platform.CurrentSystemAlias
    //         if(!alias || alias == 'res') {
    //             that.$router.push({ path: '/UnAuth' })
    //         }
    //     }
    // })
    let param = {
        // session:'C23093234468B23E8D8CABCB046'
        session:this.getSession()
    }
    this.getNavList()    // 获取平台的信息,最后存在Vuex和LocalStorage里的platform里面
    // this.getAccountCenter('LoginSession',param,function(result){
    //     console.log(result)
    // })
  },
  mounted(){
    let hostname = location.hostname
    if(hostname == "educenter-lenovo.forclass.net" || hostname == "educenter.lenovo.com"){
      document.getElementById("favicon").setAttribute("href", "/Account/images/favicon/lenovo-favicon.ico")
    }
  }
}
</script>
<style lang="scss">
body{
  padding: 0;
  margin: 0;
  padding-right: 0 !important;   // 防止页面抖动，比如this.$confirm
}
html{   // 防止滚动条出现的时候发生页面抖动
  margin-right: calc(100% - 100vw);
  overflow-x: hidden;
}
#app {
    font-family: "PingFang SC","Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

</style>
