// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

// require('babel-polyfill')
// import babelpolyfill from 'babel-polyfill'
import Vuex from 'vuex'
import Vue from 'vue'
import cookie from 'js-cookie'

Vue.use(Vuex)
Vue.config.devtools = true
export default new Vuex.Store({
    state: {
        platform: {},
        role: {},
        roleList: [], // 用户角色列表
        account: null,
        accountInfo:{},   // 个人中心用户信息
        lenovoAccount: {}, // 备授课关联信息
    },
    mutations: {
        UPDATE_USER_ROLE(state, payload) {
            state.roleList = payload
        },
        UPDATE_USER_SELECT_ROLE(state, payload) {
            state.role = payload
        },
        UPDATE_USER_LENOVO_ACCOUNT(state, payload) {
            state.lenovoAccount = payload;
        },
        UPDATE_ACCOUNT(state, payload) {
            let account = payload.account;
            state.account = account
            var date = new Date();
            var nightDate = date.toLocaleDateString() + " " + "23:59:59";
            var span = (new Date(nightDate) - new Date()) / 1000 / 3600 / 24;
            // var cookieOptions = { expires: span, path: '/' }; // 不设值 过期时间则为会话时间
            var cookieOptions = { path: '/' };
            var hostName = window.location.hostname;
            var domain = hostName;
            var domainArr = [];
            if (domain != "localhost"){
                domainArr = domain.split('.')
            }
            var arrLength = domainArr.length;
            if (arrLength >= 2) {
                domain = domainArr[arrLength - 2] + "." + domainArr[arrLength - 1];
            }
            if (hostName.indexOf('com.cn') >= 0) {
                domain = domainArr[arrLength - 3] + "." + domainArr[arrLength - 2] + "." + domainArr[arrLength - 1];
            }
            if (domain != 'localhost') {
                cookieOptions.domain = domain;
            }
            if (account != null) {
                sessionStorage.setItem('account', JSON.stringify(account));
                cookie.set('account', account, cookieOptions);
            }
        },
        UPDATE_PLATFORM(state, payload) {
            state.platform = payload.platform
        },
        UPDATE_ACCOUNT_INFO(state, payload){
            state.accountInfo = payload
        }
    },
    actions: {

    },
    getters: {
        roleScopeType: (state) => {
            const role = state.role
            // "IsAdmin": true // 是否是管理角色 ls: true false
            const IsAdmin = role.IsAdmin
            // 角色范围 ls: "区域角色" "教育局角色" "学校角色" "年级角色" "班级角色"
            const DataScopeName = role.DataScopeName
            // 角色所属区域根节点类型 ls: "根区域" "教育局" "区域/集团校" "学校" "班级" "教研组"
            const TypeName = role.TypeName
            if ((DataScopeName == "区域角色" || DataScopeName == "教育局角色")) {
                if (TypeName == "根区域"){
                    return "系统角色" 
                }else{
                    return "区域角色"
                }
            } else {
                return DataScopeName
            }
        },
        // 是不是学校管理员 或者是学校下面的老师
        isSchoolRole:(state) => {
            const role = state.role
            // 角色范围 ls: "区域角色" "教育局角色"  "学校角色" "年级角色" "班级角色"
            const DataScopeName = role.DataScopeName
            return DataScopeName == "学校角色" || DataScopeName == "班级角色" || DataScopeName == "年级角色"
        },
        isZoneRole:(state) => {
            const role = state.role
            // 角色范围 ls: "区域角色" "教育局角色"  "学校角色" "年级角色" "班级角色"
            const DataScopeName = role.DataScopeName
            return DataScopeName == "区域角色" || DataScopeName == "教育局角色"
        },
        isClassRole:(state)=>{
            const role = state.role
            // 角色范围 ls: "区域角色" "教育局角色"  "学校角色" "年级角色" "班级角色"
            const DataScopeName = role.DataScopeName
            return DataScopeName == "班级角色" || DataScopeName == "年级角色"
        },
    },

})