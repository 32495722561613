import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import App from './App.vue'
import router from './router'
import store from './store'
import util from './assets/js/util.js'
import x2js from 'x2js'
import qs from 'qs'
import VueCropper from 'vue-cropper'
import Cookies from 'js-cookie'
import { GETACCOUNTBYSESSION } from "@/http/api"

import pathArr from './assets/js/noAccountPath';

require('./assets/css/site.css')
import cdnImgDirective from './assets/js/cdnImgDirective.js'

Vue.use(VueCropper)
Vue.use(ElementUI);
Vue.use(util).use(cdnImgDirective)
Vue.config.productionTip = false
Vue.prototype.$x2js = new x2js()    // 创建x2js对象，挂到vue原型上
Vue.prototype.axios = axios
Vue.prototype.Cookies = Cookies
Vue.prototype.$http = axios
Vue.prototype.bus = new Vue;
Vue.prototype.$qs = qs

ElementUI.Dialog.props.lockScroll.default = false;   // 防止el-dialog打开时页面左右抖动

router.beforeEach((to, from, next) => {
  let query = to.query
  let urlEmbed = query.embed
  if (urlEmbed && urlEmbed != '') {
    sessionStorage['embed'] = urlEmbed
  }
  let urlDevice = query.device
  if (urlDevice && urlDevice != '') {
    sessionStorage['device'] = urlDevice
  }
  // 如果当前是登录页 去除用户信息
  if (to.path.toLocaleLowerCase() == "/account/signin"){
    sessionStorage.clear()
    Cookies.set('account', null, { expires: -1, domain: Vue.prototype.getDomain() })
  }
  if(to.meta.title){
    document.title = to.meta.title
  }

  const _this = Vue.prototype
  const toPath = to.path.toLocaleLowerCase()
  if (pathArr.some(item => item.toLocaleLowerCase() == toPath)){
    next()
  } else {
    // 个人中心 获取当前用户的角色
    GETACCOUNTBYSESSION(
      _this.$qs.stringify({ session: _this.getSession() })
    ).then((result) => {
      result = result.data.result;
      if (_this.isValidArray(result)) {
        let info = result[0];
        let userRoles = info.roles;
        let lenovoAccount = { sourceLoginName: info.sourceLoginName, sourceUserId: info.sourceUserId }
        if (_this.isValidArray(userRoles)) {
          let selRoles = _this.handleRepeatRole(userRoles);
          store.commit("UPDATE_USER_SELECT_ROLE", selRoles[0]);
          store.commit("UPDATE_USER_LENOVO_ACCOUNT", lenovoAccount);
          next()
        }
      }
    });
  }
})

// @ is an alias to /src
new Vue({
  router,
  store,
  beforeCreate(){
    Vue.prototype.$bus = this   // 使用全局Bus总线
  },
  render: h => h(App),
}).$mount('#app')
