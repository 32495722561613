let env = "production"; // production test
let hostname = location.hostname
if(hostname == "educenter.lenovo.com"){
  env = "production";
}

// const protocol = location.protocol;   // http or https
const protocol = 'https:';   // http or https
const accountUrlObject = {
    // 'test': protocol + '//localhost:80',
    'test': protocol + '//test-zzn.forclass.net/',
    'production': protocol + '//zzn.forclass.net/',
}
const accountWsUrlObject = {
    'test': protocol + '//test-ws.forclass.net/',
    'production': protocol + '//webservice.forclass.net/'
}
const webapiUrlObject = {
    'test': protocol + '//test-api.forclass.net/',
    'production': protocol + '//api.forclass.net/'
}
const newWebApi = {
    'test': protocol + '//test-api.forclass.net/',
    'production': protocol + '//api.forclass.net/'
}

const accountUrl = accountUrlObject[env]
const accountWsUrl = accountWsUrlObject[env]
const webapiUrl = webapiUrlObject[env]
const newWebApiUrl = newWebApi[env]
export { env, accountUrl, accountWsUrl, webapiUrl, newWebApiUrl}