<template>
<div v-if='loading'>
    <div class="block-div"></div>
    <div class="loading-box" v-loading="loading">
        <!-- <img v-cdnimg src="https://fordata.forclass.net/Jiaoyan/Default/237/loading-square.gif" alt=""> -->
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
        }
    },
    created(){
        this.bus.$on('loading', (data)=> {
            this.loading = !!data;
        })
    }
}
</script>

<style scoped>
    .block-div
    {
        width:100%;
        height:100%;
        cursor:wait;
        background-color:rgba(0,0,0, 0.1);
        position:fixed;
        top:0;
        left:0;
        /*- blockUI的z-index统一设置为2000 -*/
        z-index: 3000;
    }
    .loading-box{
        width: 130px;
        height: 70px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -35px 0 0 -75px;
        z-index: 3001;
    }
    .loading-box img {
    }
    .loading-box /deep/ .el-loading-mask {
        background: initial;
    }
</style>
