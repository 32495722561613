import cookie from 'js-cookie'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import {GET_PLATFORM_SYSTEMHOST,LOGIN_SESSION_JSON, GET_CAPTCHA} from './../../http/api'
import {env} from './../../http/env'
// import Bus from 'vue'
export default {
    install(Vue, options) {
        Vue.prototype.getEnv = function() {
            /*
            local          --           本地调试
            ckl            --           内网
            test           --           外测
            production     --           生产
            */
            // return env;
            return env;
        },
        // Vue.prototype.Bus = new Bus()

        Vue.prototype.getProfileData = function(systemAlias) {
            if (this.isValid(systemAlias) && systemAlias != '') {
                let account = this.loadAccount()
                if (this.isValid(account)) {
                    let profileInfo = this.getLocalVal(systemAlias + "_" + "profile" + "_" + account.loginname)
                    if (this.isValid(profileInfo) && profileInfo != '') {
                        return JSON.parse(profileInfo)
                    }
                }
            }
            return null
        }

        Vue.prototype.handleRepeatRole = function (list) {      // 去除重复角色
            let finalList = []
            for (let i = 0; i < list.length; i++) {
                let uniqueFlag = true // 改角色是否和之前的列表里有重复，是否是独一无二的
                finalList.forEach((item) => {
                    if (item.platformid == list[i].platformid &&
                        item.zoneid == list[i].zoneid &&
                        item.schoolid == list[i].schoolid &&
                        item.roleid == list[i].roleid
                    ) {
                        uniqueFlag = false // 说明有重复的角色
                    }
                })
                if (uniqueFlag == true) {
                    finalList.push(list[i])
                }
            }
            return finalList
        }

        Vue.prototype.uniqueid = function () {
            if (!Array.prototype.derangedArray) {
                Array.prototype.derangedArray = function () {
                    var a = this
                    var len = a.length;
                    for (var i = 0; i < len; i++) {
                        var end = len - 1;
                        var index = (Math.random() * (end + 1)) >> 0;
                        var t = a[end];
                        a[end] = a[index];
                        a[index] = t;
                    }
                    return a;
                }
            }
            let timestamp = new Date().getTime()
            let randomNumber = Math.floor(Math.random() * (1 - 10000000000) + 10000000000)
            let uniqueid = timestamp + randomNumber
            uniqueid = String(uniqueid).split("").derangedArray().join("")
            return uniqueid
        }

        Vue.prototype.updateProfile = function(alias, profile) {
            if (this.isValid(profile) && this.isValid(alias) && alias != '') {
                let account = this.loadAccount()
                if (this.isValid(account)) {
                    let loginName = account.loginname
                    let key = alias + "_profile_" + loginName
                    let localProfile = this.getProfileData('console')
                    localProfile = localProfile ? localProfile : {}
                    Object.assign(localProfile, profile)
                    this.updateLocal(key, JSON.stringify(profile))
                }
            }
        }

        Vue.prototype.preventDefault = function(e) {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                window.event.returnValue == false;
            }
        }

        Vue.prototype.getRequestParamValue = function(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
            var ret = regex.exec(location.search)
            return ret == null ? "" : decodeURIComponent(ret[1].replace(/\+/g, " "))
        }

        Vue.prototype.getSession = function() {
            // return "64E5FA33E71A412A9CF13411C40688D6"
            // return "0C6A0D60532482E99776ADEDB76673"
            // return "8D602C52B13F443D9013EE5ACE6A9686"
            // return "2C4901A7B8DC441ABB8A161BBC5A906F"

            let session = this.getRequestParamValue("session")
            if (session && session != "") {
                return session
            }
            var acnt = this.loadAccount()
            if (this.isValid(acnt) &&
                this.isValid(acnt.session)) {
                return acnt.session
            }
        }
        // 大小、小写字母 数字 特殊符号 满足三种
        Vue.prototype.checkPassword = function(password) {
            function isContainNum(pwd) {
                return pwd.match(/\d+/g) == null ? false : true;
            }
            function isContainLowChar(pwd) {
                return pwd.match(/[a-z]+/g) == null ? false : true;
            }
            function isContainUpChar(pwd) {
                return pwd.match(/[A-Z]+/g) == null ? false : true;
            }
            function isContainEspChar(pwd) {
                return pwd.match(/[~!@#$%^&*()_=+]+/g) == null ? false : true;
            }
            if (isContainNum(password) && isContainLowChar(password) && isContainUpChar(password)) {
                return true;
            } else if (isContainNum(password) && isContainLowChar(password) && isContainEspChar(password)) {
                return true;
            } else if (isContainNum(password) && isContainUpChar(password) && isContainEspChar(password)) {
                return true;
            } else if (isContainLowChar(password) && isContainUpChar(password) && isContainEspChar(password)) {
                return true;
            } else {
                return false;
            }
        }
        Vue.prototype.saveAccount = function(acnt) {
            if (!this.isValidArray(acnt.dlserver))
                acnt.dlserver = "/"

            if (this.isSupportJSON()) {
                sessionStorage.account = JSON.stringify(acnt)
            }
            this.$account = acnt
            this.$store.commit({
                type: 'UPDATE_ACCOUNT',
                account: acnt
            })
        }

        Vue.prototype.savePlatform = function(platform) {
            if (this.isSupportJSON()) {
                sessionStorage.platform = JSON.stringify(platform)
            }
            var date = new Date();
            var nightDate = date.toLocaleDateString() + " " + "23:59:59";
            var span = (new Date(nightDate) - new Date()) / 1000 / 3600 / 24;
            var cookieOptions = {
                // expires: span, // 不设值 过期时间则为会话时间
                path: '/'
            };
            var hostName = window.location.hostname;
            var domain = hostName;
            var domainArr = domain.split('.');
            var arrLength = domainArr.length;
            if (arrLength >= 2) {
                domain = domainArr[arrLength - 2] + "." + domainArr[arrLength - 1];
            }
            if (domain != 'localhost') {
                cookieOptions.domain = domain;
            }
            cookie.set('platform', platform, cookieOptions);
        }

        Vue.prototype.getLocalVal = function(key) {
            var value = (typeof localStorage[key] == "undefined" || localStorage[key] == "undefined") ? "" : localStorage[key]
            return value
        }

        Vue.prototype.updateLocal = function(key, value) {
            localStorage[key] = value
        }

        Vue.prototype.getSessionVal = function(key) {
            var value = (typeof sessionStorage[key] == "undefined" || sessionStorage[key] == "undefined") ? "" : sessionStorage[key]
            return value
        }

        Vue.prototype.loadAccount = function() {
            /*
            if (this.isValid(sessionStorage.account) && this.isSupportJSON()) {
                return JSON.parse(sessionStorage.account)
            } else if (this.isValid(this.getCookie('account')) && this.isSupportJSON()) {
                return this.getCookie('account')
            }
            */

            /*if (this.isValid(this.getCookie('account')) && this.isSupportJSON()) {
                return this.getCookie('account')
            }
            return null*/
            let device = this.getSessionVal('device') || window.navigator.userAgent.toLowerCase().indexOf('wke') >= 0
            let tool = this.getSessionVal('tool')
            let clientBrowser = device || tool
            let account = null
                //客户端（教师端，学生端）浏览器允许使用sessionStorage；PC浏览器只允许使用Cookie
            if (this.isSupportJSON()) {
                let cookieAccount = this.getCookie('account')
                let sessionAccount = this.getSessionVal('account')
                if (clientBrowser && this.isValid(sessionAccount) && sessionAccount != '') {
                    account = JSON.parse(sessionAccount)
                } else {
                    if (cookieAccount) {
                        account = cookieAccount
                    } else {
                        account = (sessionAccount && sessionAccount != "") ? JSON.parse(sessionAccount) : null
                    }
                }
            }
            return account
        }
        Vue.prototype.loadPlatform = function() {
            let device = this.getSessionVal('device') || window.navigator.userAgent.toLowerCase().indexOf('wke') >= 0
            let tool = this.getSessionVal('tool')
            let clientBrowser = device || tool
            let platform = null
            if (this.isSupportJSON()) {
                let cookiePlatform = this.getCookie('platform')
                let sessionPlatform = this.getSessionVal('platform')
                if (clientBrowser && this.isValid(sessionPlatform) && sessionPlatform != '') {
                    platform = JSON.parse(sessionPlatform)
                } else if (this.isValid(cookiePlatform) && cookiePlatform != '') {
                    platform = this.getCookie('platform')
                }
            }
            return platform
        }

        Vue.prototype.updateSession = function(key, value) {
            sessionStorage[key] = value
        }

        Vue.prototype.getCookie = function(name) {
            return cookie.getJSON(name)
        }

        Vue.prototype.setCookie = function(name, value, options) {
            if (this.isValid(name)) {
                cookie.set(name, value, options)
            }
        }

        //var g_upgrademsgshowed = false

        Vue.prototype.isSupportJSON = function() {
            var support = JSON != null && typeof JSON != 'undefined'
            if (!support && !g_upgrademsgshowed) {
                g_upgrademsgshowed = true
                alert("您的浏览器版本过低，请升级浏览器。")
            }

            return support
        }

        Vue.prototype.isParent = function() {
            var account = this.loadAccount()
            if (this.isValid(account))
                return account.role == "家长"

            return false
        }

        Vue.prototype.isStudent = function() {
            var account = this.loadAccount()
            if (this.isValid(account))
                return account.role == "学生"

            return false
        }

        Vue.prototype.isTeacher = function() {
            var account = this.loadAccount()
            if (this.isValid(account))
                return account.role == "教师"

            return false
        }

        Vue.prototype.isValid = function(value) {
            return value != null && typeof value != 'undefined'
        }

        Vue.prototype.isValidInt = function(prop, minValue) {
            if (!this.isValid(prop) || prop <= minValue)
                return false

            return true
        }

        Vue.prototype.isValidArray = function(arr) {
            if (!this.isValid(arr) || !this.isValid(arr.length) || arr.length < 1)
                return false
            return true
        }

        Vue.prototype.formateString = function(str) {
            return this.isValid(str) ? str : ""
        }

        Vue.prototype.encrypt = function(word, keyStr, ivStr) {
                //console.log(word);
                keyStr = keyStr ? keyStr : "ckb";
                ivStr = ivStr ? ivStr : "ckb";
                let key = CryptoJS.enc.Utf8.parse(keyStr);
                let iv = CryptoJS.enc.Utf8.parse(ivStr);
                let srcs = CryptoJS.enc.Utf8.parse(word);
                key = CryptoJS.MD5(key)
                iv = CryptoJS.MD5(iv)
                let encrypted = CryptoJS.AES.encrypt(srcs, key, {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    // padding: CryptoJS.pad.ZeroPadding
                    padding: CryptoJS.pad.Pkcs7
                });
                // return encrypted.toString();
                return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
            },
        // 解密
        Vue.prototype.decrypt = function(word, keyStr, ivStr) {
            keyStr = keyStr ? keyStr : "ckb";
            ivStr = ivStr ? ivStr : "ckb";
            var key = CryptoJS.enc.Utf8.parse(keyStr);
            let iv = CryptoJS.enc.Utf8.parse(ivStr);
            key = CryptoJS.MD5(key)
            iv = CryptoJS.MD5(iv)
            var decrypt = CryptoJS.AES.decrypt(word, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                // padding: CryptoJS.pad.ZeroPadding
                padding: CryptoJS.pad.Pkcs7
            });
            return decrypt.toString(CryptoJS.enc.Utf8);
        }

        Vue.prototype.getNavigationUrl = function(alias) {
            let data = this.getHostInfo(alias)
            if (this.isValid(data)) {
                let protocol = location.protocol
                if (protocol == 'https:') {
                    return protocol + "//" + data.HostName + data.Path
                } else {
                    return data.Url
                }
            }
            return ''
        }

        Vue.prototype.getNavigationHostName = function(alias) {
            let data = this.getHostInfo(alias)
            if (this.isValid(data)) {
                let protocol = location.protocol
                return protocol + "//" + data.HostName
            }
            return ''
        }

        Vue.prototype.getNavigationPath = function(alias) {
            let data = this.getHostInfo(alias)
            if (this.isValid(data)) {
                return data.Path
            }
            return null
        }

        Vue.prototype.getHostInfo = function(alias) {
            let platform = this.$store.state.platform
            if (this.isValid(platform)) {
                let hostList = platform.NavList
                let linkList = platform.InNavList
                if (this.isValid(hostList) && this.isValid(linkList)) {
                    hostList = hostList.concat(linkList)
                }
                if (this.isValidArray(hostList)) {
                    for (var i = 0; i < hostList.length; i++) {
                        var host = hostList[i]
                        let childList = host.ModList
                        if (host.Alias == alias) {
                            return host
                            break
                        }
                        if (this.isValidArray(childList)) {
                            for (let j = 0; j < childList.length; j++) {
                                let childHost = childList[j]
                                if (childHost.Alias == alias) {
                                    return childHost
                                    break
                                }
                            }
                        }
                    }
                }
            }
            return null
        }

        Vue.prototype.getResultData = function(data) {
            if (typeof data.result != 'undefined' && data.result != null) {
                return data.result
            }

            return data
        }

        Vue.prototype.getReturnCode = function(data) {
            if (this.isValid(data.ReturnCode)) {
                return data.ReturnCode
            }

            return 0
        }

        Vue.prototype.getResultNextPage = function(data) {

            if (this.isValid(data.NextPage)) {
                return data.NextPage
            }

            return 0
        }

        Vue.prototype.getDomain = function() {
            var domain = window.location.hostname.split('.')
            var arrLength = domain.length
            if (arrLength >= 2) {
                domain = domain[arrLength - 2] + '.' + domain[arrLength - 1]
            }else if(arrLength === 1){
                domain = domain[0];
            }

            return domain
        }

        Vue.prototype.validateSession = function(callback, session) {
            // let loginUrl = "/Account/SignIn"
            let clientSession = session || this.getSession()
            let clientCookie = cookie.get('account')
            if ((!this.isValid(clientSession) && !this.isValid(clientCookie)) && !session) {  // 没有cookie说明未登录，跳转登录页面
                //console.log('未获取到本地session')
                // location.href = loginUrl   // 没有登录的时候跳转登录页面
                return
            } else {
                let param = {
                        "session": clientSession,
                        "page": 0,
                        "hostName": window.location.hostname
                    }
                    if(param.hostName === 'localhost'){
                        param.hostName = process.env.VUE_APP_HOSTNAME;
                    }
                    //axios.post(this.getWSDomain() + "/ANAService.asmx/LoginSessionJson", qs.stringify(param)).then((response) => {})
                    /*
                    axios.all([axios.post(this.getWSDomain() + "/ANAService.asmx/LoginSessionJson", qs.stringify(param))]).then(axios.spread(function(result) {

                    }))
                    return
                    */
                
                LOGIN_SESSION_JSON(this.$qs.stringify(param)).then((response)=>{
                    this.loadSession(response.data.result, this)
                    if (typeof(callback) == "function") {
                        callback(response)
                    }
                })
                // let api = this.getWSDomain() + this.getWebServiceURL7('LoginSessionJson')
                // this.addToQueue(api, param, function(result, that) {
                //     that.loadSession(result, that)
                //     if (typeof(callback) == "function") {
                //         callback()
                //     }
                // }, this, false)
                    //this.addToQueue(this.getWebServiceURL7("LoginSessionJson"), param, this.loadSession, this, true)
            }
        }

        Vue.prototype.loadSession = function(result, that) {
            if (that.isValidArray(result)) {
                var account = result[0]
                let clientSession = that.getSession()
                let serverSession = account.session
                let loginUrl = "/Account/SignIn"
                if (that.isValid(serverSession)) {
                    var realname = account.realname
                    var showname = account.showname
                    var username = (realname == null || realname == "") ? showname : realname
                    var classidx = (account.classidx > 0 ? account.classidx : 0)
                    var classname = ((account.classname == null || account.classname == undefined) ? "" : account.classname)
                    var acnt = {
                        "userid": account.userid,
                        "session": account.session,
                        "loginname": account.showname,
                        "realname": account.realname,
                        "account": username,
                        "role": account.role,
                        "period": account.period,
                        "coin": account.coin,
                        "level": account.level,
                        "email": account.email,
                        "avatar": account.avatar,
                        "dlserver": account.dlserver,
                        "zoneidx": account.zoneidx,
                        "schoolidx": account.schoolidx,
                        "role1": account.role,
                        "classidx": classidx,
                        "classname": classname,
                        "zonename": account.zone,
                        "schoolname": account.school,
                        'zonelevel': account.zonelevel,
                        'platformzonelevel': account.platformzonelevel,
                        'zonelevelname': account.zonelevelname,
                        'showname': account.showname,
                        'sourceid': account.sourceid
                    }
                    that.saveAccount(acnt)
                } else {
                    console.log('client session not equal with server session')
                        //location.href = loginUrl
                }
            } else {
                console.log('请检查服务器返回的session')
                sessionStorage.removeItem('account')
                    //that.setCookie('account', null, { expires: -1, path: that.getDomain() })
                cookie.set('account', null, { expires: -1, domain: that.getDomain() })
                that.$store.commit({ type: 'UPDATE_ACCOUNT', account: { Account: null } })
                    //location.href = loginUrl
            }
        }

        var g_ajaxQueue = []
        var g_ajaxIsRunning = false
        Vue.prototype.addToQueue = function(methodName, inputObj, handleFunc, target, runNow) {
            g_ajaxQueue.push(this.sendRequest(methodName, inputObj, handleFunc, target))
            if (runNow) {
                /*
                axios.all(g_ajaxQueue).then(axios.spread((response) => {
                    console.log(response)
                }))
                */
            }
        }

        Vue.prototype.sendRequest = function(methodName, inputObj, handleFunc, target, next) {
            if (this.isValid(inputObj)) {
                var dataObj = {}

                var session = this.getSession()
                if (session != null)
                    Object.assign(dataObj, {
                        "session": session
                    })
                else
                    Object.assign(dataObj, {
                        "session": ""
                    })
                if (!this.isValidInt(inputObj.page, -1))
                    inputObj.page = 0

                Object.assign(dataObj, inputObj)

                var contentType = "application/x-www-form-urlencoded"
                var dataType = "json"
                g_ajaxIsRunning = true
                if (!this.isValid(window.ajaxRandomArr)) {
                    window.ajaxRandomArr = []
                }
                window.ajaxRandomArr.push(Math.random())
                axios({
                    url: methodName,
                    method: "post",
                    baseURL: this.getWSDomain(),
                    /*
                    transformRequest: [function(data) {

                        return data
                    }],
                    transformResponse: [function(data) {

                    }],
                    */
                    /*//谨慎使用
                    headers: { 'Access-Control-Allow-Origin': '*' },
                    params: qs.stringify(dataObj),
                    paramsSerializer: function(params) {
                        return qs.stringify(params, { arrarFormat: 'brackets' })
                    },
                    */
                    data: this.$qs.stringify(dataObj),
                    timeout: 0,
                    withCredentials: false,
                    /*//谨慎使用
                    adapter: function(config) {

                    },
                    */
                    auth: null, //谨慎使用
                    //responseType: dataType,   //谨慎使用
                    xsrfCookieName: 'XSRF-TOKEN',
                    xsrfHeaderName: 'X-XSRF-TOKEN',
                    //maxContentLength: 2000,
                    /*
                    validateStatus: function(status) { //研究一下
                        return status >= 200 && status < 300
                    },
                    maxRedirects: 5,
                    httpAgent: new http.Agent({ keepAlive: true }),
                    httpsAgent: new https.Agent({ keepAlive: true }),
                    proxy: {
                        host: 'ws.ckl.com',
                        port: '80',
                        auth: {}
                    },
                    cancelToken: new axios.CancelToken(function(cancel) {

                    })
                    */
                }).then((response) => {
                    g_ajaxIsRunning = false
                    window.ajaxRandomArr.pop()
                    var d = response.data
                    if (this.isValid(d)) {
                        var rc = this.getReturnCode(d)
                        if (rc == 1) {
                            var np = this.getResultNextPage(d)
                            if (np > 0) {
                                inputObj.page = np
                                this.addToQueue(methodName, inputObj, handleFunc, target, true)
                            }

                            var result = this.getResultData(d)
                            handleFunc(result, target, np)
                                // need get more pages
                        } else if (rc < 1) {
                            // hide waiting msg
                            // return error
                            var result = this.getResultData(d)
                            handleFunc(result, target, 0)
                        }
                    } else {
                        handleFunc(-1, target, 0)
                    }
                }).catch(function(error) {
                    console.log(error)
                    g_ajaxIsRunning = false
                })
            }
        }

        Vue.prototype.getNavList = function(callback) {
            let hostName = window.location.hostname
            // let hostName = 'educenter-lenovo.forclass.net'
            if (hostName == "localhost") {
                hostName = process.env.VUE_APP_HOSTNAME;
            }
            let param = {
                session: '',
                hostName: hostName,
                platformNumber: '',
                platformName: ''
            }
            // 获取平台信息 由于此处只需要 hostName 其他参数传空
            GET_PLATFORM_SYSTEMHOST(param).then((response)=>{
                let result = response.data.result
                let platform = null
                if (this.isValidArray(result)) {
                    platform = result[0]
                } else {
                    platform = this.getDefaultPlatformInfo()
                }
                // window.document.title = platform.PlatformSystemName  // 把原来的给页面标签文字隐藏
                this.$store.commit({
                    type: 'UPDATE_PLATFORM',
                    platform: platform
                })
                localStorage["platform"] = JSON.stringify(platform)
                if (typeof(callback) == 'function') {
                    callback(platform)
                }        
            })
        }

        Vue.prototype.getDefaultPlatformInfo = function() {
            return {
                PlatformSystemNumber: '000001',
                PlatformSystemName: 'ForClass教育资源公共服务平台',
                CurrentSystemAlias: 'home',
                ZoneIdx: 1,
                NavList: [{
                        Name: '首页',
                        Alias: 'home',
                        HostName: 'paas.forclass.net',
                        Enabled: true
                    },
                    {
                        Name: '资源',
                        Alias: 'res',
                        HostName: 'res.forclass.net',
                        Enabled: true
                    },
                    {
                        Name: '视频',
                        Alias: 'v',
                        HostName: 'v.forclass.net',
                        Enabled: true
                    },
                    {
                        Name: '社区',
                        Alias: 'cm',
                        HostName: 'cm.forclass.net',
                        Enabled: true
                    },
                    {
                        Name: '作业',
                        Alias: 'zzn',
                        HostName: 'zzn.forclass.net',
                        Enabled: true
                    },
                    {
                        Name: '空间',
                        Alias: 'zone',
                        HostName: 'zone.forclass.net',
                        Enabled: true
                    },
                    {
                        Name: '统计',
                        Alias: 'cm',
                        HostName: 'st.forclass.net',
                        Enabled: true
                    },
                    {
                        Name: '管理',
                        Alias: 'console',
                        HostName: 'console.forclass.net',
                        Enabled: true
                    }
                ]
            }
        }
        Vue.prototype.sendCPPMessage = function(data, type, className, callback, multiple, maxNum, maxSize) {
            
        }

        Vue.prototype.getDownloadXml = function(list, type, className) {
            let $xml = $('<download>', {
                'type': type,
                'classname': className
            });
            if (this.isValidArray(list)) {
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    var url = item.url;
                    var name = item.name;
                    var source = item.source || '资源导出';
                    var size = item.size || '未知';
                    var dataid = item.dataid;
                    var $item = $('<item>', {
                        'url': url,
                        'showname': name,
                        'source': source,
                        'Size': size,
                        'dataid': dataid
                    });
                    $item.appendTo($xml);
                }
            }
            return $xml;
        }

        Vue.prototype.setAccountResourceQuery = function(callback, sysType, localConfig) {
            let param = {
                session: this.getSession(),
                sysType: sysType || 2
            }
            let unitList = this.getLocalVal("unitList")
            unitList = this.isValid(unitList) && unitList != '' ? JSON.parse(unitList) : []
            let accountQuery = {
                PeriodName: this.getLocalVal("speriod"),
                SubjectName: this.getLocalVal("ssubject"),
                EditionName: this.getLocalVal("sedition"),
                TermName: this.getLocalVal("sterm"),
                BookName: this.getLocalVal("sbook"),
                UnitList: unitList,
                RSName: this.getLocalVal("sscope"),
                RTName: this.getLocalVal("stype"),
                ATName: this.getLocalVal("sattachtype"),
                CTName: this.getLocalVal("screatetype"),
            }
            if (this.isValid(localConfig)) {
                let obj = {
                    PeriodIdx: this.getLocalVal(localConfig.periodIdx),
                    SubjectIdx: this.getLocalVal(localConfig.subjectIdx),
                    EditionIdx: this.getLocalVal(localConfig.editionIdx),
                    TermIdx: this.getLocalVal(localConfig.termIdx),
                    BookIdx: this.getLocalVal(localConfig.bookIdx)
                }
                accountQuery = Object.assign(accountQuery, obj)
            }
            accountQuery = JSON.stringify(accountQuery)
            param.arqName = accountQuery
            let api = this.getWSDomain() + this.getWebServiceURL4('SetAccountResourceQuery')
            this.$http.post(api, this.$qs.stringify(param)).then((res) => {
                if (typeof(callback) == 'function') {
                    callback()
                }
            })
        }

        Vue.prototype.isImg = function(url) {
            if (this.isValid(url)) {
                let index = url.indexOf('?')
                if (index > 0) {
                    url = url.substring(0, index)
                }
                let ext = url.substring(url.lastIndexOf('.')).toLowerCase()
                let arr = [".jpg", ".jpeg", ".png", ".bmp", ".gif", ".svg"]
                if (arr.indexOf(ext) >= 0) {
                    return true
                }
            }
            return false
        }

        Vue.prototype.getDownloadServer = function() {
            let acnt = this.loadAccount();
            return this.isValid(acnt) && this.isValidArray(acnt.dlserver) ? acnt.dlserver : "/";
        }

        Vue.prototype.getAttachFileUrl = function(url) {
            let ret = ""
            if (this.isValid(url)) {
                let items = url.split('|')
                let params = "";
                if (this.isValidArray(items) && items.length >= 3) {
                    ret = this.getDownloadServer() + "AttachFiles/" + items[2] + "/" + encodeURI(items[1])
                } else {
                    ret = url
                }
            }
            return ret.trim()
        }

        Vue.prototype.replaceUrl2OSSCDN = function(url) {
            if (url) {
                url = url.replace("forcass-res.oss-cn-qingdao.aliyuncs.com", "fcdata.forclass.net");
                url = url.replace("//AttachFiles", "/AttachFiles");
                url = url.replace("//CKLAttachFiles", "/CKLAttachFiles");
            }

            return url;
        }

        Vue.prototype.uniqueid = function() {
            if (!Array.prototype.derangedArray) {
                Array.prototype.derangedArray = function() {
                    var a = this
                    var len = a.length;
                    for (var i = 0; i < len; i++) {
                        var end = len - 1;
                        var index = (Math.random() * (end + 1)) >> 0;
                        var t = a[end];
                        a[end] = a[index];
                        a[index] = t;
                    }
                    return a;
                }
            }
            let timestamp = new Date().getTime()
            let randomNumber = Math.floor(Math.random() * (1 - 10000000000) + 10000000000)
            let uniqueid = timestamp + randomNumber
            uniqueid = String(uniqueid).split("").derangedArray().join("")
            return uniqueid
        }
        Vue.prototype.bannerScaleOnIOS = function() {
            window.onload = function() {
                document.addEventListener('touchstart', function(event) {
                    if (event.touches.length > 1) {
                        event.preventDefault();
                    }
                })
                var lastTouchEnd = 0;
                document.addEventListener('touchend', function(event) {
                    var now = (new Date()).getTime();
                    if (now - lastTouchEnd <= 300) {
                        event.preventDefault();
                    }
                    lastTouchEnd = now;
                }, false)
            }
        }
        Vue.prototype.getRequestParamValue = function (name){
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
            var ret = regex.exec(location.search);
            return ret == null ? "" : decodeURIComponent(ret[1].replace(/\+/g, " "));
        }
        //防抖
        Vue.prototype.debounce = function (fn,delay) {
            let timer = null;
            return (...args) =>{
                clearTimeout(timer);
                timer = setTimeout(()=>{
                    fn(...args)
                },delay);
            }
        }
        Vue.prototype.throttle = function(fn, gapTime) {
            let _lastTime = null;
            return function () {
              let _nowTime = + new Date()
              if (_nowTime - _lastTime > gapTime || !_lastTime) {
                fn();
                _lastTime = _nowTime
              }
            }
        }

        Vue.prototype.splitSuffix = function(name){
            if(!name){
                return '';
            }else {
                return name.substring(name.lastIndexOf('.') + 1).toLowerCase()
            }
        }

        Vue.prototype.replaceSession = function(str){
            var reg = /session=\w+/g
            return str.replace(reg,'session=')
        }

        Vue.prototype.loadScript = function(list, module){
            let fragment = document.createDocumentFragment()
            let head = document.getElementsByTagName('head')[0]
            let str = `[module=${module}]`
            let doms = document.querySelectorAll(str)
            if(!(doms && doms.length >0)){
                list.forEach(item => {
                    let script = document.createElement('script')
                    script.type = item.type
                    script.src = item.name
                    script.setAttribute('module', module)
                    try {
                        //IE浏览器认为script是特殊元素,不能再访问子节点;报错;
                        script.appendChild(document.createTextNode(item.name));
                    } catch (ex) {
                        script.text = item.name;
                    }
                    fragment.appendChild(script)
                });
                head.appendChild(fragment)
            }
        }

        Vue.prototype.removeScript = function(module){
            let head = document.getElementsByTagName('head')[0]
            let str = `[module=${module}]`
            var doms = document.querySelectorAll(str)
            for (let index = 0; index < doms.length; index++) {
                let element = doms[index];
                head.removeChild(element)
            }
        }

        // 获取图形验证码
        Vue.prototype.onGetCaptcha = function(uuid) {
            return new Promise((resolve, reject) => {
                GET_CAPTCHA({ id: uuid }).then(res => {
                    resolve(window.URL.createObjectURL(res.data));
                })
            })
        }

        Vue.prototype.isMobile = function() {
            return navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
        }


        // 调用用户信息接口需要上传的参数
        Vue.prototype.getAccountRoleParam = function(){
            // 获取当前的平台信息
            let platform = {};
            try {
                platform = JSON.parse(localStorage["platform"]);
            } catch (error) {
                console.log('platform', error);
            }

            let specialXStr = {
                "hostname": location.hostname,
                "platformNumber": platform.PlatformSystemNumber || '',
                "rnamelist" : ''
            }

            if (specialXStr.hostname == "localhost") {
                specialXStr.hostname = process.env.VUE_APP_HOSTNAME;
            }

            return {
                session: this.getSession(),
                specialXStr: JSON.stringify(specialXStr)
            }
        }
    }
}
