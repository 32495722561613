<template>
  <div class="quick-tool-bar">
    <div class="win-operate-list clearfix">
      <div class="button-item pull-left min" @click="onOperateClicked('min')"></div>
      <div class="button-item pull-left close" @click="onOperateClicked('close')"></div>
    </div>
  </div>
  
</template>
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      showToolBar: false
    };
  },
  methods: {
    onOperateClicked(type){
      if(type == "min"){
        window.winApi && window.winApi.minimizeWindow()
      }
      if(type == "close"){
        window.winApi && window.winApi.closeWindow()
      }
    }
  },
  watch: {
    '$route': {
      handler(){
        var device = this.getSessionVal("device")
        if(device && device == "lenovowin"){
          this.showToolBar = true
        }
        if(window.winApi){
          this.showToolBar = true
        }
        if(this.$route.path.toLowerCase() == "/" || this.$route.path.toLowerCase() == '/account/lenovomoduleselect'){
          this.showToolBar = false
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.quick-tool-bar{
  display: none;
  position: fixed;
  top: 3px;
  right: 6px;
  .win-operate-list{
    .button-item{
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
    .button-item.min{
      background-image: url('~@Account/images/tool/icon_min_dark@2x.png');
      background-size: 100%;
      margin-right: 6px;
    }
    .button-item.close{
      background-image: url('~@Account/images/tool/icon_close_dark@2x.png');
      background-size: 100%;
    }
  }
}
</style>